// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/sanity/client.ts"
);
import.meta.hot.lastModified = "1715872649553.1592";
}
// REMIX HMR END

import { createClient } from "@sanity/client";
import { stegaEnabled, projectId, dataset, studioUrl } from "./projectDetails";

// Do not import this into client-side components unless lazy-loaded
export const client = createClient({
    projectId,
    dataset,
    useCdn: true,
    apiVersion: "2023-03-20",
    stega: {
        enabled: stegaEnabled,
        studioUrl,
    },
});


// export const client = createClient({
//     projectId: "usbgtvds",
//     dataset: "production",
//     apiVersion: "2022-03-07",
// });